import React from 'react';
// import { Modal } from 'antd';
import { Form, InputNumber, message } from 'antd';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
const UpdateCommissionModal = ({refetch, sellerToUpdate, show, setShow }) => {
    const handleClose = () => setShow(false);
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const updateCommission = ({ seller_id, seller_commission }) => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/admin-dashboard/commission/update-commission`
        const finalData = {
            seller_id: seller_id,
            seller_commission: seller_commission
        }
        return axios.put(finalUrl, finalData, { withCredentials: true })
    }
    const commissionMutation = useMutation({
        mutationFn: updateCommission,
        onSuccess: () => {
            refetch()
            handleClose()
        },
        onError: (error) => {
            console.log(error);
            if(error.response.data.detail[0].msg === "Value error, commission rate must be between 0.00 and 0.50"){
                messageApi.open({
                    type: 'error',
                    content: 'Commission rate must be between 0.00 and 0.50',
                });
            }else{
                messageApi.open({
                    type: 'error',
                    content: 'Something went wrong',
                });
            }
        }
    })
    const onFinish = (values) => {
        commissionMutation.mutate({seller_id:sellerToUpdate,seller_commission:values.number})
    };
    const validateNumber = (_, value) => {
        if (value === undefined || value === null) {
            return Promise.reject('Please input a number!');
        }
        if (value < 0 || value > 0.5) {
            return Promise.reject('The number must be between 0.00 and 0.50');
        }
        return Promise.resolve();
    };
    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Seller Commission (ID:{sellerToUpdate})</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="flex justify-center items-center ">
                        <div className=" p-8 rounded-lg  w-full max-w-md">
                            <Form
                                form={form}
                                name="numberInputForm"
                                onFinish={onFinish}
                                layout="vertical"
                                className="space-y-4 w-full"
                                style={{ boxShadow: "none" }}
                            >
                                <Form.Item
                                    name="number"
                                    label="Enter a number between 0.00 and 0.50"
                                    rules={[
                                        {
                                            required: true,
                                            validator: validateNumber,
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        step={0.01}
                                        precision={2}
                                        className="w-full"
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                    {contextHolder}
                </Modal.Body>

            </Modal>
        </>
    );
};
export default UpdateCommissionModal;
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const useGetAttributes = (id) => {
    // const [page, setPage] = useState(initialPage);

    const getAttributes = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/products/attributes/get-attributes?category_id=${id}`;
        return axios.get(finalUrl, { withCredentials: true });
    };

    const { data, isLoading, isError, error, refetch } = useQuery({ queryKey: ["get-attributes",id], queryFn: getAttributes, enabled: Boolean(id) });

    const attributes = data?.data;

    return {
        attributes,
        isLoading,
        isError,
        error,
        refetch

    };
};

export default useGetAttributes;
import React from "react"
import { useState } from "react";
import { Container, Row, Col, FormCheck } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { IoChevronBack } from "react-icons/io5";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { message } from 'antd';
import useGetCategories from "../Categories/hooks/useGetCategories";
import { Accordion, Card, Form, Button } from 'react-bootstrap';
import { PlusCircle, MinusCircle } from 'lucide-react';
import axios from "axios";
import { useMutation } from "@tanstack/react-query";

const CategorySelector = ({ categories, selectedCategories, categoryIsRequired, onCategorySelect, onRequiredToggle }) => {
    const [expandedCategories, setExpandedCategories] = useState({});

    const toggleExpand = (categoryId) => {
        setExpandedCategories((prev) => ({
            ...prev,
            [categoryId]: !prev[categoryId]
        }));
    };

    const isChildCategory = (categoryId) => {
        return categories.some((cat) => cat.category_id === categoryId && Array.isArray(cat.children_categories) && cat.children_categories.length > 0);
    };

    return (
        <div>
            <h4 className="text-lg font-medium mb-2">Category Relations</h4>
            <Accordion>
                {Array.isArray(categories) && categories.map((category) => (
                    <Card key={category.category_id} className="mb-2">
                        <Accordion.Item eventKey={category.category_id.toString()}>
                            <Accordion.Header onClick={() => toggleExpand(category.category_id)}>
                                <div className="flex justify-between items-center w-full px-4 py-2">
                                    <div className="flex items-center">
                                        <Form.Check
                                            style={{ display: 'flex', alignItems: 'center' }}
                                            type="checkbox"
                                            id={`category-${category.category_id}`}
                                            label={category.category_name}
                                            custom // for custom layout
                                        >
                                            <Form.Check.Input checked={selectedCategories.includes(category.category_id)} onChange={() => onCategorySelect(category.category_id)} disabled={isChildCategory(category.category_id)} className="mr-2" /> {/* Add Tailwind margin class here */}
                                            <Form.Check.Label className="text-sm" >{category.category_name}</Form.Check.Label>
                                        </Form.Check>
                                    </div>

                                    <div className="flex items-center">
                                        <FormCheck
                                            style={{ display: 'flex', alignItems: "center" }}
                                            type="switch"
                                            id={`required-${category.category_id}`}
                                            label="Required"
                                            checked={categoryIsRequired[category.category_id] || false}
                                            onChange={() => onRequiredToggle(category.category_id)}
                                            disabled={!selectedCategories.includes(category.category_id)}
                                        />
                                    </div>
                                </div>
                            </Accordion.Header>

                            <Accordion.Body>
                                {isChildCategory(category.category_id) && (
                                    <div className="ml-4">

                                        {expandedCategories[category.category_id] && (
                                            <CategorySelector
                                                categories={category.children_categories}
                                                selectedCategories={selectedCategories}
                                                categoryIsRequired={categoryIsRequired}
                                                onCategorySelect={onCategorySelect}
                                                onRequiredToggle={onRequiredToggle}
                                            />
                                        )}
                                    </div>
                                )}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Card>
                ))}
            </Accordion>
        </div>
    );
};

const CreateAttribute = () => {
    const history = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();

    const {
        categories,
        isLoading,
        isError,
        error,
        refetch
    } = useGetCategories()
    const [attributeName, setAttributeName] = useState('');
    const [attributeValues, setAttributeValues] = useState(['']);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [categoryIsRequired, setCategoryIsRequired] = useState({});

    const addAttributeValue = () => {
        setAttributeValues([...attributeValues, '']);
    };

    const removeAttributeValue = (index) => {
        const newValues = [...attributeValues];
        newValues.splice(index, 1);
        setAttributeValues(newValues);
    };

    const handleAttributeValueChange = (index, value) => {
        const newValues = [...attributeValues];
        newValues[index] = value;
        setAttributeValues(newValues);
    };

    const handleCategorySelect = (categoryId) => {
        setSelectedCategories(prev => {
            if (prev.includes(categoryId)) {
                return prev.filter((id) => id !== categoryId);
            } else {
                return [...prev, categoryId];
            }
        });
    };

    const handleRequiredToggle = (categoryId) => {
        setCategoryIsRequired(prev => ({
            ...prev,
            [categoryId]: !prev[categoryId]
        }));
    };
    const createAttribute = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/admin-dashboard/attributes/create-attribute`;
        const finalData = {
            attribute_name: attributeName,
            attribute_values: attributeValues.filter((value) => value !== ''),
            category_relation: selectedCategories.map((categoryId) => ({
                category_id: categoryId,
                is_required: categoryIsRequired[categoryId] || false
            }))
        }
        return axios.post(finalUrl, finalData, { withCredentials: true })
    }


    const createAttributeMutation = useMutation({
        mutationFn: createAttribute,
        onSuccess: () => {
            history("/attributes")
        },
        onError: (error) => {
            console.log(error);
            if (error.response.data.detail[0].msg === "input has at least one invalid category_id") {
                messageApi.open({
                    type: 'error',
                    content: 'input has at least one invalid category_id',
                });
            } else {

                messageApi.open({
                    type: 'error',
                    content: 'Something went wrong',
                });
            }
        }
    })

    const handleSubmit = (e) => {
        e.preventDefault();
        createAttributeMutation.mutate()
        const finalData = {
            attribute_name: attributeName,
            attribute_values: attributeValues.filter((value) => value !== ''),
            category_relation: selectedCategories.map((categoryId) => ({
                category_id: categoryId,
                is_required: categoryIsRequired[categoryId] || false
            }))
        }
        console.log(finalData);

    };

    return (
        <Container className="d-flex justify-content-center ">
            <Row className="flex justify-start ">
                <Col className="flex justify-start ">
                    <Link
                        style={{ position: "absolute", top: "5px ", left: "0", padding: "16px" }}
                        to={"/nextpageoflogin"}
                    >
                        <IoChevronBack color="#000" size={50} />
                    </Link>
                </Col>
            </Row>
            <div className="container mx-auto p-6 overflow-x-auto rounded-t-lg  custom-scrollbar">
                <div className="p-4">
                    <h2 className="text-2xl font-bold mb-4">Attribute Creator</h2>
                    <Form onSubmit={handleSubmit} style={{ width: "100%", maxWidth: "100%" }}>
                        <h3 className="text-lg font-medium mb-2">Attribute Name</h3>

                        <Form.Group className="mb-3">
                            <Form.Control

                                required
                                type="text"
                                value={attributeName}
                                onChange={(e) => setAttributeName(e.target.value)}
                            />
                        </Form.Group>
                        <h6 className=" font-medium mb-2">Attribute Values</h6>
                        {attributeValues.map((value, index) => (
                            <Form.Group key={index} className="mb-3 flex items-center">
                                <Form.Control
                                    required
                                    type="text"
                                    value={value}
                                    onChange={(e) => handleAttributeValueChange(index, e.target.value)}
                                />
                                {index > 0 && (
                                    <Button
                                        variant="danger"
                                        className="ml-2"
                                        onClick={() => removeAttributeValue(index)}
                                    >
                                        <MinusCircle size={16} />
                                    </Button>
                                )}
                            </Form.Group>
                        ))}
                        <Button variant="primary" className="mb-3 flex " onClick={addAttributeValue}>
                            <PlusCircle className=" inline" size={16} /> Add Value
                        </Button>
                        <CategorySelector
                            categories={categories}
                            selectedCategories={selectedCategories}
                            categoryIsRequired={categoryIsRequired}
                            onCategorySelect={handleCategorySelect}
                            onRequiredToggle={handleRequiredToggle}
                        />
                        <Button type="submit" variant="primary">
                            Create Attribute
                        </Button>
                    </Form>
                </div>
                {contextHolder}
            </div>
        </Container>
    );
};

export default CreateAttribute;

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form, InputNumber, message } from 'antd';
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
function NewCouponModal({ refetch, show, setShow }) {
    const [messageApi, contextHolder] = message.useMessage();
    const [form] = Form.useForm();

    const onFinish = (values) => {
        console.log('Form values:', values);
        createCouponMutation.mutate(values)
        form.resetFields()
    };

    const validatePercentage = (_, value) => {
        if (value >= 0.01 && value <= 0.50) {
            return Promise.resolve();
        }
        return Promise.reject(new Error('Percentage must be between 0.01 and 0.50'));
    };
    const handleClose = () => setShow(false);
    const createCoupon = (values) => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/admin-dashboard/coupons/create-coupon`
        return axios.post(finalUrl, values, { withCredentials: true })
    }
    const createCouponMutation = useMutation({
        mutationFn: createCoupon,
        onSuccess: () => {
            setShow(false);
            refetch()
        },
        onError: (error) => {
            if (error.response.data.detail[0].msg === "Value error, discount_percentage must be between 0.01 and 0.50") {
                messageApi.open({
                    type: 'error',
                    content: 'Commission rate must be between 0.00 and 0.50',
                });
            } else {
                messageApi.open({
                    type: 'error',
                    content: 'Something went wrong',
                });
            }
        }
    })
    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>New Coupon</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="max-w-md mx-auto p-6 bg-white rounded-lg ">
                        <Form
                            form={form}
                            name="discountForm"
                            onFinish={onFinish}
                            layout="vertical"
                            className='w-full shadow-none'
                        >
                            <Form.Item
                                name="parent_count"
                                label="Parent Count"
                                rules={[{ required: true, message: 'Please input the parent count' }]}
                            >
                                <InputNumber min={1} className="w-full" />
                            </Form.Item>

                            <Form.Item
                                name="discount_percentage"
                                label="Discount Percentage"
                                rules={[
                                    { required: true, message: 'Please input the discount percentage' },
                                    { validator: validatePercentage },
                                ]}
                            >
                                <InputNumber

                                    step={0.01}
                                    precision={2}
                                    className="w-full"
                                />
                            </Form.Item>

                            <Form.Item
                                name="children_count"
                                label="Children Count"
                                rules={[{ required: true, message: 'Please input the children count' }]}
                            >
                                <InputNumber min={0} className="w-full" />
                            </Form.Item>

                            <Form.Item
                                name="children_discount_percentage"
                                label="Children Discount Percentage"
                                rules={[
                                    { required: true, message: 'Please input the children discount percentage' },
                                    { validator: validatePercentage },
                                ]}
                            >
                                <InputNumber
                                    step={0.01}
                                    precision={2}
                                    className="w-full"
                                />
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="w-full bg-blue-500 hover:bg-blue-600">
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                    {contextHolder}
                </Modal.Body>
            </Modal>
        </>
    );
}

export default NewCouponModal;
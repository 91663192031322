import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useMemo, useState } from 'react';

const useGetCommissionSeller = (page, shopName, sellerId) => {
    const [isNext, setIsNext] = useState(false);

    const getCommissionSeller = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/admin-dashboard/commission/get-seller?${sellerId !==0 && sellerId && `seller_id=${sellerId}&`}shop_name=${shopName}&page=${page}`;
        return axios.get(finalUrl, { withCredentials: true });
    };

    const { data, isLoading, isError, error, refetch } = useQuery({
        queryKey: ["get-commission-seller", page, shopName, sellerId],
        queryFn: getCommissionSeller
    });

    const sellers = data?.data;
    useMemo(() => {

        if (Array.isArray(sellers) && sellers.length === 11) {
            setIsNext(true)
        } else {
            setIsNext(false)
        }
    }, [data])
    return {
        sellers,
        isLoading,
        isError,
        error,
        refetch,
        isNext,
    };
};

export default useGetCommissionSeller;
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const useGetReturnedTransactions = () => {
    // const [page, setPage] = useState(initialPage);

    const getReturnedTransactions = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/admin-dashboard/transactions/get-returned-transactions`;
        return axios.get(finalUrl, { withCredentials: true });
    };

    const { data, isLoading, isError, error, refetch } = useQuery({queryKey: ["get-returned-transactions"],queryFn:getReturnedTransactions});

    const transactions = data?.data;

    return {
        transactions,
        isLoading,
        isError,
        error,
        refetch
        // page,
        // setPage,
    };
};

export default useGetReturnedTransactions;
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { message } from "antd"

function NewDesignerModal({ refetch, show, setShow }) {
    const [messageApi, contextHolder] = message.useMessage();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [designerName, setDesignerName] = useState('');
    const createDesigner = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/admin-dashboard/products/create-designer`
        const finalData = { designer_name: designerName }
        return axios.post(finalUrl, finalData, { withCredentials: true })
    }
    const createDesignerMutation = useMutation({
        mutationFn: createDesigner,
        onSuccess: () => {
            setDesignerName("")
            handleClose()
            refetch()
        },
        onError: (error) => {
            if (error.response.data.detail[0].msg === "designer duplication") {
                messageApi.open({
                    type: 'error',
                    content: 'designer duplication',
                });
            } else {

                messageApi.open({
                    type: 'error',
                    content: 'Something went wrong',
                });
            }
            console.log(error);
        }
    })
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log({ designer_name: designerName });
        createDesignerMutation.mutate();
    };


    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Body>
                    <form onSubmit={handleSubmit} className="max-w-md mx-auto p-4 shadow-none rounded">
                        <h2 className="text-2xl font-semibold mb-4">Designer Information</h2>
                        <div className="mb-4">
                            <label htmlFor="designerName" className="block text-gray-700 font-medium mb-2">
                                Designer Name
                            </label>
                            <input
                                type="text"
                                id="designerName"
                                name="designerName"
                                value={designerName}
                                onChange={(e) => setDesignerName(e.target.value)}
                                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                                placeholder="Enter designer name"
                                required
                            />
                        </div>
                        <button
                            type="submit"
                            className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600 transition duration-200"
                        >
                            Submit
                        </button>
                    </form>
                    {contextHolder}
                </Modal.Body>
            </Modal>
        </>
    );
}

export default NewDesignerModal;
import React from "react"
import { useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { IoChevronBack } from "react-icons/io5";
import 'react-quill/dist/quill.snow.css';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import useGetReturnedTransactions from "./Hooks/useGetReturnedTransactions";
import TransactionModal from "./Modals/TransactionModal";
import "./style.css"
const Transactions = () => {
    const [show, setShow] = useState(false);
    const [refNo, setRefNO] = useState(0);
    const [transferName, setTransferName] = useState("");
    const [transferIban, setTransferIban] = useState("");
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const {
        transactions,
        isLoading,
        isError,
    } = useGetReturnedTransactions();
    // console.log(transactions);
    return (
        <>
            <Container className="d-flex justify-content-center ">
                <Row className="flex justify-start ">
                    <Col className="flex justify-start ">
                        <Link
                            style={{ position: "absolute", top: "5px ", left: "0", padding: "16px" }}
                            to={"/nextpageoflogin"}
                        >
                            <IoChevronBack color="#000" size={50} />
                        </Link>
                    </Col>
                </Row>
                <div className="container mx-auto p-6 overflow-x-auto rounded-t-lg  custom-scrollbar mt-12">
                    <table className="min-w-full bg-white border border-gray-300">
                        <thead>
                            <tr className="bg-gray-100 rounded-lg ">

                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    ref_no
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    date_detected
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    date_reimbursed
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    transfer_name
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    transfer_iban
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    transfer_amount
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    reimburse_reason
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    transfer_date
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    created_at
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    email
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    seller_id
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    company_title
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    seller_TC_no
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    company_tax_no
                                </th>
                                <th className="sticky -right-4 px-6 py-3 text-left text-xs font-medium bg-blue-500 text-white uppercase tracking-wider">
                                    action
                                </th>

                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {isLoading ? (
                                <>
                                    {[...Array(2)].map((_, rowIndex) => (
                                        <tr key={rowIndex}>
                                            {[...Array(15)].map((_, colIndex) => (
                                                <td key={colIndex} className="px-6 py-4 border-b border-gray-200">
                                                    <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
                                                </td>
                                            ))}
                                            <td className="px-6 py-4 border-b border-gray-200">
                                                <div className="h-4 bg-blue-500 rounded animate-pulse"></div>
                                            </td>
                                        </tr>
                                    ))}
                                </>
                            ) : isError ? (
                                <tr>
                                    <td colSpan="9" className="px-6 py-4 text-center bg-red-100 text-red-700 border border-red-400">
                                        <div className="flex justify-center items-center">
                                            <span className="font-bold">Error: </span>
                                            <span className="ml-2">{"An error occurred. Please try again."}</span>
                                        </div>
                                    </td>
                                </tr>
                            ) : Array.isArray(transactions) && transactions.length === 0 ? (
                                <>
                                    <tr className="rounded-lg hover:bg-gray-300">
                                        <td colSpan="9" className="px-6 py-4 text-center bg-yellow-100 text-yellow-700 border border-yellow-400">
                                            <div className="flex justify-center items-center">
                                                <span className="font-bold">No Transaction</span>
                                                
                                            </div>
                                        </td>
                                    </tr>
                                </>
                            ) : (
                                Array.isArray(transactions) && transactions.map((row, rowIndex) => (
                                    <tr className="rounded-lg hover:bg-gray-300" key={rowIndex}>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {row.ref_no}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {row.date_detected}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {row.date_reimbursed}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {row.transfer_name}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {row.transfer_iban}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {row.transfer_amount}{row.transfer_currency}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {row.reimburse_reason}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {row.transfer_date}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {row.created_at}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {row.email}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {row.seller_id}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {row.company_title}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {row.seller_TC_no}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {row.company_tax_no}
                                        </td>
                                        <td className="sticky -right-4 bg-blue-500 px-6 py-4 whitespace-nowrap text-sm text-white">
                                            <button onClick={() => {
                                                console.log("sjoidf");
                                                setRefNO(row.ref_no)
                                                setTransferName(row.transfer_name)
                                                setTransferIban(row.transfer_iban)
                                                handleShow()

                                            }} type="button" className="border rounded p-2 hover:scale-110">action</button>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>



                <TransactionModal
                    show={show}
                    onHide={handleClose}
                    refNo={refNo}
                    transferName={transferName}
                    transferIban={transferIban}
                />
                <ToastContainer />
            </Container>
        </>
    )
}
export default Transactions
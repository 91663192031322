

import { toast } from 'react-toastify';
import { useState } from 'react';
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import OtpInput from 'react-otp-input';
import useGetReturnedTransactions from './Hooks/useGetReturnedTransactions';
const Otp = ({ refNo, onHide, setHasMailSent }) => {
    const {refetch} = useGetReturnedTransactions()

    const [otp, setOtp] = useState('');
    const completeRetry = (code) => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/admin-dashboard/transactions/complete-retry`
        const finalData = {
            ref_no: refNo,
            code: code
        }
        return axios.post(finalUrl, finalData, { withCredentials: true })
    }
    const completeRetryMutation = useMutation({
        mutationFn: completeRetry,
        onSuccess: () => {
            toast.success("Done",{toastId:"email-sent",closeButton:false})
            onHide()
            setHasMailSent(false)
            setOtp("")
            refetch()
        },
        onError: (error) => {
            console.log(error);
            if (error.response.data.detail[0].msg === "invalid code") {
                toast.error("Invalid code")
            } else if (error.response.data.detail[0].msg === "code expired") {
                toast.error("Code expired")
            } else if (error.response.data.detail[0].msg === "transaction error") {
                toast.error("Transaction error")
            } else {
                toast.error("Error")
            }
        }
    })
    return (
        <>
            <h2>Please enter the code </h2>
            <div className='w-full flex justify-center align-middle'>
                <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                    containerStyle={{ width: "400px" }}
                    inputStyle={{ width: "100%", padding: "10px", margin: "10px" }}
                    onPaste={(e) => {
                        const data = e.clipboardData.getData('text');
                        completeRetryMutation.mutate(data)
                    }}
                />
            </div>
            <div className='flex justify-end  '>
                <button onClick={() => completeRetryMutation.mutate(otp)} className='me-8 border p-2 rounded bg-blue-500 text-white hover:scale-110 hover:bg-blue-400'>submit</button>
            </div>
        </>
    )
}
export default Otp
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {  Minus, ChevronRight, ChevronDown } from 'lucide-react';
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from "react-router-dom";
import {message } from 'antd';

function PreviewModal({ show, setShow, category}) {
    // const [show, setShow] = useState(false);
    const history = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const [expanded, setExpanded] = useState({});

    const handleClose = () => setShow(false);
    const toggleExpand = (categoryName) => {
        setExpanded(prev => ({ ...prev, [categoryName]: !prev[categoryName] }));
    };

    const createCategory = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/admin-dashboard/attributes/create-category`;
        const finalData = category
        return axios.post(finalUrl, finalData,{withCredentials: true})
    }
    const createCategoryMutation = useMutation({
        mutationFn: createCategory,
        onSuccess: () => {
            history("/categories")
        },
        onError: (error) => {
            console.error(error);
            messageApi.open({
                type: 'error',
                content: 'Something went wrong',
            });
        }
    })

    const renderCategory = (cat, depth = 0) => {
        const hasChildren = cat.children_categories && cat.children_categories.length > 0;
        const isExpanded = expanded[cat.category_name];

        return (
            <div key={cat.category_name} className={`ml-${depth * 4}`}>
                <div className="flex items-center py-1">
                    {hasChildren ? (
                        <button onClick={() => toggleExpand(cat.category_name)} className="mr-2">
                            {isExpanded ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
                        </button>
                    ):(
                        <button className="mr-2 cursor-not-allowed">
                            <Minus size={16} /> 
                        </button>
                    )}
                    <span>{cat.category_name}</span>
                </div>
                {isExpanded && hasChildren && (
                    <div className={`ml-${depth * 4}`}>
                        {cat.children_categories.map(child => renderCategory(child, depth + 1))}
                    </div>
                )}
            </div>
        );
    };


    return (
        <>
            
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h3>
                        Are you sure about createing this category?
                    </h3>
                    <div className="border rounded p-4 mt-4">
                        <h3 className="text-xl font-bold mb-2">Category Preview</h3>
                        {renderCategory(category)}
                    </div>
                    {contextHolder}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        No
                    </Button>
                    <Button variant="primary" onClick={()=>{createCategoryMutation.mutate()}}>
                        Yes, Create
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default PreviewModal;
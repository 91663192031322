import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useState, useMemo } from 'react';
const useGetContracts = (page) => {
    const [isNext, setIsNext] = useState(false);

    const getContracts = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/admin-dashboard/contracts/get-contracts?page=${page}`;
        return axios.get(finalUrl, { withCredentials: true });
    };

    const { data, isLoading, isError, error, refetch } = useQuery({ queryKey: ["get-contracts",page], queryFn: getContracts });

    const contracts = data?.data;
    useMemo(() => {

        if (Array.isArray(contracts) && contracts.length === 11) {
            setIsNext(true)
        } else {
            setIsNext(false)
        }
    }, [data])
    return {
        contracts,
        isLoading,
        isError,
        error,
        refetch,
        isNext
    };
};

export default useGetContracts;
import './App.css';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.css';
import { authProtectedRoutes, publicRoutes } from './routes/index.js';
import Authmiddleware from './routes/route.js';
function App() {
  // setTimeout(() => {
  //   localStorage.removeItem('isLoggedIn');
  // }, 3600000)
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<Login />} />
        <Route path="/invoice" element={<Invoice />} />
        <Route path="/nextpageoflogin" element={<NextPageOfLogin />} />
        <Route path="/notification" element={<NotificationPage />} />
        <Route path="/token" element={<Token />} />
        <Route path="/crm" element={<Chat />} />
        <Route path="/doc" element={<Document />} /> */}
        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware>
                {route.component}
              </Authmiddleware>
            }
            key={idx}
          />
        ))}
        {publicRoutes.map((route, idx) => (
          <Route
            key={idx}
            path={route.path}
            element={route.component}
          />
        ))}
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import {create} from 'zustand';

const useImageHoverStore = create((set) => ({
  show: false,
  src: '',
  // target: null,
  setShow: (show) => set({ show:show }),
  setSrc: (src) => set({ src:src }),
  // setTarget: (target) => set({ target }),
  // toggleShow: () => set((state) => ({ show: !state.show })),
  // reset: () => set({ show: false, src: '', target: null }),
}));

export default useImageHoverStore;
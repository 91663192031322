import React, { useState } from 'react';
import AddValueModal from '../modals/AddValueModal';
const AttributeValues = ({ attRefetch,attributesData }) => {
    // const [selectedValues, setSelectedValues] = useState({});
    const [show, setShow] = useState(false);
    const [attId, setAttId] = useState(0);
    const handleSelectChange = (attributeId, value) => {
        
        if(value === "add-new"){
            setAttId(attributeId)
            setShow(true);
        }
    };

    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 rounded-lg bg-slate-50 p-2">
            {Array.isArray(attributesData) && attributesData.map((attribute) => (
                <div key={attribute.attribute_id} className="mb-2">
                    <select
                        // value={selectedValues[attribute.attribute_id] || ''}
                        onChange={(e) => {
                            handleSelectChange(attribute.attribute_id, e.target.value)
                        }}
                        required={attribute.is_required === 1}
                        className={`w-full p-2  ${attribute.is_required === 1 ? "border-red-600" : "border border-gray-300"}  rounded shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 `}
                    >
                        <option value="">{attribute.attribute_name}</option>
                        {attribute.attribute_values.map((value) => (
                            <option key={value.attribute_value_id} value={value.attribute_value_id}>
                                {value.attribute_value}
                            </option>
                        ))}
                        <option value="add-new">
                            +Add new
                        </option>
                    </select>

                    
                </div>
            ))}
            <AddValueModal attRefetch={attRefetch} attId={attId} show={show} setShow={setShow} />
        </div>
    );
};


export default AttributeValues;
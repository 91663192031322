import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { IoChevronBack } from "react-icons/io5";
import useGetCategories from '../Categories/hooks/useGetCategories';
import { Form } from 'react-bootstrap';
import useGetAttributes from '../Attributes/hooks/useGetAttributes';
import { Select, Tag, Input, ColorPicker } from 'antd';
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';

const { Option } = Select;
const tagRender = (props) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    return (
        <Tag
            color="blue"
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{ marginRight: 3 }}
        >
            {label}
        </Tag>
    );
};
const CreateCampaign = () => {
    const history = useNavigate()
    const [messageApi, contextHolder] = message.useMessage();

    const [selectedValueIds, setSelectedValueIds] = useState([]);
    const [level1, setLevel1] = useState('');
    const [level2, setLevel2] = useState('');
    const [level3, setLevel3] = useState('');
    const [level2Options, setLevel2Options] = useState([]);
    const [level3Options, setLevel3Options] = useState([]);
    const [deepestCategoryId, setDeepestCategoryId] = useState(0);
    const [title, setTitle] = useState("");
    const [color, setColor] = useState("");
    const {
        categories,
        isLoading,
        isError,
        error,
        refetch
    } = useGetCategories()
    const {
        attributes,
        isLoading: attIsLoading,
        isError: attIsError,
        error: attError,
        refetch: attRefetch
    } = useGetAttributes(deepestCategoryId)


    useEffect(() => {
        if (level1) {
            const selectedCategory = categories.find(cat => cat.category_id.toString() === level1);
            setLevel2Options(selectedCategory?.children_categories || []);
            setLevel2('');
            setLevel3('');
            setDeepestCategoryId(level1);
        } else {
            setLevel2Options([]);
            setLevel3Options([]);
            setDeepestCategoryId(null);
        }
    }, [level1]);

    useEffect(() => {
        if (level2) {
            const selectedCategory = level2Options.find(cat => cat.category_id.toString() === level2);
            setLevel3Options(selectedCategory?.children_categories || []);
            setLevel3('');
            setDeepestCategoryId(level2);
        } else {
            setLevel3Options([]);
            if (level1) setDeepestCategoryId(level1);
        }
    }, [level2]);

    useEffect(() => {
        if (level3) {
            setDeepestCategoryId(level3);
        } else if (level2) {
            setDeepestCategoryId(level2);
        }
    }, [level3]);
    const handleLevel1Change = (e) => {
        setLevel1(e.target.value);
        setLevel2('');
        setLevel3('');
    };
    const handleLevel2Change = (e) => {
        setLevel2(e.target.value);
        setLevel3('');
    };
    const handleSelect = (valueIds, attribute) => {
        console.log(valueIds);

        setSelectedValueIds(prev => {
            const filteredPrev = prev.filter(id => !attribute.attribute_values.some(v => v.attribute_value_id === id));
            return [...filteredPrev, ...valueIds];
        });
    };
    const createCampaign = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/admin-dashboard/attributes/create-campaign`
        const finalData = {
            title: title,
            filter: {
                ...(selectedValueIds ? { attribute_value_ids: selectedValueIds } : {}),

                ...(color ? { color: color } : {}),

                ...(deepestCategoryId ? { category_id: deepestCategoryId } : {})
            }
        }
        return axios.post(finalUrl, finalData, { withCredentials: true })
    }
    const createCampaignMutation = useMutation({
        mutationFn: createCampaign,
        onSuccess: () => {
            setColor("")
            setTitle("")
            setSelectedValueIds([])
            history("/campaigns")
        },
        onError: (error) => {
            console.log(error);
            message.error("error")
        }
    })
    return (
        <Container className="d-flex justify-content-center ">
            <Row className="flex justify-start ">
                <Col className="flex justify-start ">
                    <Link
                        style={{ position: "absolute", top: "5px ", left: "0", padding: "16px" }}
                        to={"/campaigns"}
                    >
                        <IoChevronBack color="#000" size={50} />
                    </Link>
                </Col>
            </Row>
            <div className="container mt-28 mx-auto p-6 overflow-x-auto rounded-t-lg bg-gray-100 custom-scrollbar">
                <div className="p-4">
                    <h2 className="text-2xl font-bold mb-4">Campaign Creator</h2>


                    <div className="space-y-4">
                        <div className="flex items-center gap-4">
                            <div className="flex-1">
                                <Input

                                    placeholder="Title"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    className="w-full h-10"
                                />
                            </div>
                            <div className="flex-shrink-0">
                                <ColorPicker format='hex' value={color} onChangeComplete={(e) => setColor(e.toHexString())} size="large" showText />
                            </div>
                        </div>

                        <Form.Select
                            value={level1}
                            onChange={handleLevel1Change}
                            className="w-full p-2 border border-gray-300 rounded shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        >
                            <option value="">Select Level 1 Category</option>
                            {Array.isArray(categories) && categories.map((category) => (
                                <option key={category.category_id} value={category.category_id}>
                                    {Array.isArray(category.children_categories) && category.children_categories.length > 0 ? ("+") : (null)} {category.category_name}
                                </option>
                            ))}
                        </Form.Select>
                        {level2Options.length > 0 && (
                            <Form.Select
                                value={level2}
                                onChange={handleLevel2Change}
                                className="w-full p-2 border border-gray-300 rounded shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            >
                                <option value="">Select Level 2 Category</option>
                                {level2Options.map((category) => (
                                    <option key={category.category_id} value={category.category_id}>
                                        {Array.isArray(category.children_categories) && category.children_categories.length > 0 ? ("+") : (null)} {category.category_name}
                                    </option>
                                ))}
                            </Form.Select>
                        )}
                        {level3Options.length > 0 && (
                            <Form.Select
                                value={level3}
                                onChange={(e) => setLevel3(e.target.value)}
                                className="w-full p-2 border border-gray-300 rounded shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            >
                                <option value="">Select Level 3 Category</option>
                                {level3Options.map((category) => (
                                    <option key={category.category_id} value={category.category_id}>
                                        {category.category_name}
                                    </option>
                                ))}
                            </Form.Select>
                        )}
                        {Array.isArray(attributes) && attributes.length > 0 && (
                            <>
                                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 rounded-lg bg-slate-50 p-2">
                                    {Array.isArray(attributes) && attributes.map((attribute) => (
                                        <div key={attribute.attribute_id} className="mb-1">
                                            <Select
                                                mode="multiple"
                                                className={`w-full p-2  ${attribute.is_required === 1 ? "border-red-600" : "border border-gray-300"}  rounded shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 `}
                                                onChange={(values) => handleSelect(values, attribute)}

                                                placeholder={attribute.attribute_name}
                                                tagRender={tagRender}
                                                style={{ width: '100%' }}
                                                optionFilterProp="children"
                                            >
                                                {attribute.attribute_values.map((value) => (
                                                    <Option key={value.attribute_value_id} value={value.attribute_value_id}>{value.attribute_value}</Option>
                                                ))}
                                            </Select>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                        <div className="flex justify-end items-center mt-4">

                            <button
                                onClick={() => {
                                    createCampaignMutation.mutate()
                                }}
                                className="mt-4 bg-green-500 text-white px-4 py-2 rounded"
                            >
                                Create
                            </button>
                        </div>
                    </div>
                    {contextHolder}
                </div>
            </div>
        </Container>
    );
};

export default CreateCampaign;
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form, Input, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import useGetContracts from '../hooks/useGetContracts';
function NewContractModal({ show, setShow }) {
    // const [show, setShow] = useState(false);
    const {refetch} = useGetContracts()
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState([]);
    const createContract = (formData) => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/admin-dashboard/contracts/create-contract`;
        const finalData = formData
        return axios.post(finalUrl, finalData, { withCredentials: true })
    }
    const createContractMutation = useMutation({
        mutationFn: createContract,
        onSuccess: () => {
            message.success('Contract submitted successfully');
            form.resetFields();
            setFileList([]);
            refetch()
            handleClose()
        },
        onError: (error) => {
            console.log(error);
            message.error('Error submitting contract');
        }
    })
    const onFinish = (values) => {
        const formData = new FormData();
        formData.append('contract_name', values.contract_name);
        formData.append('contract', fileList[0]?.originFileObj);
        createContractMutation.mutate(formData)
        
    };

    const beforeUpload = (file) => {
        const acceptedTypes = ['application/pdf', 'image/jpeg', 'image/jpg'];
        const isAcceptedType = acceptedTypes.includes(file.type);

        if (!isAcceptedType) {
            message.error('You can only upload PDF or JPG files!');
            return Upload.LIST_IGNORE;
        }

        const isLessThan10M = file.size / 1024 / 1024 < 10;
        if (!isLessThan10M) {
            message.error('File must be smaller than 10MB!');
            return Upload.LIST_IGNORE;
        }

        return false; // Allow the file to be added to the list
    };

    const handleFileChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };
    return (
        <>


            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        form={form}
                        name="contractUpload"
                        onFinish={onFinish}
                        layout="vertical"
                        className="max-w-lg w-full"
                    >
                        <Form.Item
                            name="contract_name"
                            label="Contract Name"
                            rules={[
                                { required: true, message: 'Please enter the contract name' },
                                { min: 3, message: 'Contract name must be at least 3 characters' },
                                { max: 100, message: 'Contract name cannot exceed 100 characters' }
                            ]}
                        >
                            <Input
                                placeholder="Enter contract name"
                                className="w-full"
                            />
                        </Form.Item>

                        <Form.Item
                            name="contract_file"
                            label="Contract File"
                            rules={[
                                { required: true, message: 'Please upload a contract file' }
                            ]}
                            valuePropName="fileList"
                            getValueFromEvent={(e) => {
                                if (Array.isArray(e)) {
                                    return e;
                                }
                                return e?.fileList;
                            }}
                        >
                            <Upload
                                beforeUpload={beforeUpload}
                                onChange={handleFileChange}
                                fileList={fileList}
                                maxCount={1}
                                accept=".pdf, .jpg, .jpeg"  // Accepts PDF and JPG formats

                            >
                                <Button icon={<UploadOutlined />}>Select File</Button>
                                <div className="mt-2 text-gray-500 text-sm">
                                    Supported format: PDF only. Max file size: 10MB
                                </div>
                            </Upload>
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="w-full"
                                disabled={fileList.length === 0}
                            >
                                Upload Contract
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default NewContractModal;
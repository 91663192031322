import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useMemo, useState } from 'react';

const useGetCoupons = (page, option) => {
    const [isNext, setIsNext] = useState(false);

    const getCommissionSeller = () => {
        const finalUrl =
            `${process.env.REACT_APP_API_URL}/api/v1/admin-dashboard/coupons/get-coupons?page=${page}&${option && `option=${option}`}`;
        return axios.get(finalUrl, { withCredentials: true });
    };

    const { data, isLoading, isError, error, refetch } = useQuery({
        queryKey: ["get-coupons", page, option,],
        queryFn: getCommissionSeller
    });

    const coupons = data?.data;
    useMemo(() => {

        if (Array.isArray(coupons) && coupons.length === 11) {
            setIsNext(true)
        } else {
            setIsNext(false)
        }
    }, [data])
    return {
        coupons : Array.isArray(coupons) && coupons.length ===11 ? coupons.slice(0, -1): coupons,
        isLoading,
        isError,
        error,
        refetch,
        isNext,
    };
};

export default useGetCoupons;
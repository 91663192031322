import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { message } from "antd"

function AddValueModal({ attRefetch, attId, show, setShow }) {
    const [value, setValue] = useState("");
    const [messageApi, contextHolder] = message.useMessage();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const createAttributeValue = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/admin-dashboard/attributes/create-attribute-value`
        const finalData = {
            attribute_id: attId,
            attribute_value: value
        }
        console.log(finalData);
        return axios.post(finalUrl, finalData, { withCredentials: true })
    }
    const createAttributeValueMutation = useMutation({
        mutationFn: createAttributeValue,
        onSuccess: () => {
            handleClose()
            attRefetch()
            setValue('')
        },
        onError: (error) => {
            console.log(error);
            if (error.response.data.detail[0].msg === "invalid attribute_id") {
                messageApi.open({
                    type: 'error',
                    content: 'invalid attribute_id',
                });
            } else if (error.response.data.detail[0].msg === "attribute_value duplication") {
                messageApi.open({
                    type: 'error',
                    content: 'attribute_value duplication',
                });
            } else {
                messageApi.open({
                    type: 'error',
                    content: 'Something went wrong',
                });
            }
        }
    })
    const handleSubmit = (e) => {
        e.preventDefault();
        createAttributeValueMutation.mutate();
    };
    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Body>
                    <form onSubmit={handleSubmit} className="max-w-md mx-auto p-4 shadow-none rounded">
                        <h2 className="text-2xl font-semibold mb-4">New Attribute Value</h2>
                        {/* Designer Name Input */}
                        <div className="mb-4">
                            <label htmlFor="designerName" className="block text-gray-700 font-medium mb-2">
                                New Value:
                            </label>
                            <input
                                type="text"
                                id="designerName"
                                name="attValue"
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                                placeholder="Enter Value"
                                required
                            />
                        </div>
                        
                        <button
                            type="submit"
                            className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600 transition duration-200"
                        >
                            Submit
                        </button>
                    </form>
                    {contextHolder}
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AddValueModal;
import React from "react"



import Modal from 'react-bootstrap/Modal';
import { Container } from "react-bootstrap";
import Image from 'react-bootstrap/Image';

const LightBox = ({ modalOpen, setModalOpen, src }) => {

    const toggleModal = () => {
        setModalOpen(!modalOpen)
    }

    return (
        <>
            
            <Modal
                show={modalOpen}
                onHide={toggleModal}
                size="xl"
            >

                <Modal.Body>
                    <Image src={src} fluid />
                </Modal.Body>

            </Modal>
            {/* </Container> */}
        </>
    )
}

export default LightBox

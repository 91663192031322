import React from "react"
import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { MdLogout } from "react-icons/md";
import { Link } from "react-router-dom";
import axios from "axios";
const NextPageOfLogin = () => {
    const history = useNavigate();
    const logOut = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/auth/logout`, { withCredentials: true })
            console.log(res.data);
            history("/")
        } catch (e) {
            console.log(e);
        }
    }
    return (
        <>
            <Container className="m-auto ">
                <Row className="d-flex justify-content-center align-items-center text-center mt-5">
                    <Col className="d-flex justify-content-between mt-5">
                        <h1 className="text-center">Choose which part you want to change</h1>
                        <Button onClick={logOut} className="p-3 " variant="danger"> <MdLogout size={20} /> </Button>
                    </Col>
                </Row>
                <Row className="mt-10">
                    {[
                        { path: "/invoice", label: "Invoice" },
                        { path: "/token", label: "Token" },
                        { path: "/notification", label: "Notification" },
                        { path: "/crm", label: "CRM" },
                        { path: "/doc", label: "Pending Sellers" },
                        { path: "/report", label: "Report" },
                        { path: "/products", label: "Products" },
                        { path: "/transactions", label: "Transactions" },
                        { path: "/transfer", label: "Transfer" },
                        { path: "/commission", label: "Commission" },
                        { path: "/coupons", label: "Coupons" },
                        { path: "/categories", label: "Categories" },
                        { path: "/attributes", label: "Attributes" },
                        { path: "/designers", label: "Designers" },
                        { path: "/sellers", label: "Sellers" },
                        { path: "/contract", label: "Contract" },
                        { path: "/campaigns", label: "Campaigns" },
                    ].map((item, index) => (
                        <Col key={index} className="col-lg-3 col-xs-4">
                            <Link to={item.path}>
                                <div className="box p-4 text-center bg-white shadow-md rounded-lg hover:bg-indigo-100 transition duration-200 ease-in-out">
                                    <span className="text-lg font-semibold text-gray-800">{item.label}</span>
                                </div>
                            </Link>
                        </Col>
                    ))}
                </Row>

            </Container>
        </>
    )
}
export default NextPageOfLogin
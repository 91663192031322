import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const useReports = (productId,page) => {
    // const [page, setPage] = useState(initialPage);

    const getReports = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/admin-dashboard/reports/get-reports?product_id=${productId}&page=${page}`;
        return axios.get(finalUrl, { withCredentials: true });
    };

    const { data, isLoading, isError, error } = useQuery({queryKey: ["get-reports",productId,page],queryFn:getReports});

    const reports = data?.data;

    return {
        reports,
        isLoading,
        isError,
        error,
        // page,
        // setPage,
    };
};

export default useReports;
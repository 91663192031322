import React from "react"
import { useState } from "react";
import Button from 'react-bootstrap/Button';
import { Container, Row, Col } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom";
import { IoChevronBack } from "react-icons/io5";
import { ToastContainer } from 'react-toastify';
import useGetPendingProducts from "./hooks/useGetPendingProducts";
import "react-toastify/dist/ReactToastify.css";
import { Skeleton } from "antd";
import { ListGroup } from 'react-bootstrap';
import { ProductApprovalPanel } from "./ProductApprovalPanel";
import useImageHoverStore from "./store/useImageHoverStore";
const ProductApprove = () => {
    const { show, src, setShow } = useImageHoverStore();
    // const [show, setShow] = useState(false);
    const [page, setPage] = useState(0);
    const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);
    const {
        products,
        isLoading,
    } = useGetPendingProducts(page);
    const handleBack = () => {
        if (page !== 0) {
            setPage(page - 1)
        }
    }
    const handleNext = () => {
        if (products.length === 11) {
            setPage(page + 1)
        }
    }

    //-------------------------------
    // const [approvedProducts, setApprovedProducts] = useState([]);

    // const handleApprove = (productId) => {
    //     setApprovedProducts([...approvedProducts, productId]);
    //     // Here you would typically make an API call to update the product status
    //     console.log(`Product ${productId} approved`);
    // };
    //-------------------------------
    return (
        <>
            {/* <Container style={{ position: "relative", flexDirection: "row", flexWrap: "wrap" }} className="d-flex justify-content-center vh-100 vw-100 "> */}
            <Container >
                <Link
                    style={{ position: "absolute", top: "5px ", left: "0", padding: "16px" }}
                    to={"/nextpageoflogin"}
                >
                    <IoChevronBack color="#000" size={50} />
                </Link>
                <Row className="d-flex justify-content-center align-items-center flex-row mb-5 mt-5 ">
                    <Col className="col-4 w-30 ">

                        {/* <Image  src="https://butiko-public-media-storage1.s3.eu-central-1.amazonaws.com/logo.png" fluid /> */}
                    </Col>
                </Row>

                <Row className="d-flex justify-content-center align-items-center   ">

                    {isLoading ?

                        (
                            <>
                                <Skeleton active />
                            </>
                        ) :
                        (
                            <>
                                <Col className="d-flex justify-content-center align-items-center ">
                                    <ListGroup>
                                        {products.length === 0 ? (
                                            <>
                                                <h1 className="text-center">no product</h1>
                                            </>
                                        ) : products.map((product) => (
                                            <ListGroup.Item key={product.product_id}>
                                                <ProductApprovalPanel
                                                    product={product}
                                                // onApprove={handleApprove}
                                                />
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                </Col>
                                {products.length !== 0 && (<Col className="col-7 d-flex justify-content-end mt-3">
                                    <Button onClick={handleBack} className="btn btn-secondary me-1">back</Button>
                                    <Button onClick={handleNext} className="btn btn-secondary">next</Button>
                                </Col>)}
                            </>
                        )}
                </Row>
                <ToastContainer />
                <Modal show={show} onHide={handleClose}>

                    <Modal.Body>

                        <Image src={src} fluid />
                    </Modal.Body>

                </Modal>

            </Container>
        </>
    );
}
export default ProductApprove
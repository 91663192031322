import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const useGetCategories = () => {
    // const [page, setPage] = useState(initialPage);

    const getCategories = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/seller-dashboard/products/attributes/get-categories`;
        return axios.get(finalUrl, { withCredentials: true });
    };

    const { data, isLoading, isError, error, refetch } = useQuery({ queryKey: ["get-categories"], queryFn: getCategories });

    const categories = data?.data;

    return {
        categories,
        isLoading,
        isError,
        error,
        refetch

    };
};

export default useGetCategories;
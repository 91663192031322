import React from "react"
import { useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BiShow } from "react-icons/bi";
import { ToastContainer } from "react-toastify";
const Login = () => {
    const history = useNavigate()
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [showPassWord, setShowPassword] = useState(false);
    const isAuth = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/auth/is-authenticated`
        return axios.get(finalUrl, { withCredentials: true })
    }
    const { isLoading, isError, error: authError, data } = useQuery({ queryKey: ["is-auth"], queryFn: isAuth });
    let status
    if (isLoading) {
        status = "Checking your authentication..."
    } else if (isError) {
        console.error("Error checking authentication:", authError);
    } else {
        status = data?.data
        // console.log(data?.data);
        if (status.is_authenticated) {
            history("/nextpageoflogin")
        }
    }
    const postForm = () => {
        const finalData = {
            email: email,
            password: password,
            role: "admin"
        }
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/auth/login`
        return axios.post(finalUrl, finalData, { withCredentials: true })
    }
    const loginMutation = useMutation({
        mutationFn: postForm,
        onSuccess: (e) => {
            if (e.data.info === "login successful") {
                // localStorage.setItem('isLoggedIn', 'true'); // Notice: Stores as a string
                history("/nextpageoflogin")
            }
        },
        onError: (error) => {
            console.log(error.response.data.detail[0].msg);
            setError(error.response.data.detail[0].msg);
        }
    })
    const handleSubmit = (e) => {
        e.preventDefault()
        loginMutation.mutate()
    }
    return (
        <>
            {isLoading ? (null) : (<>
                <Container style={{ flexDirection: "row", flexWrap: "wrap" }} className="d-flex justify-content-center  ">
                    
                    <Row className="d-flex justify-content-center align-items-center mt-5">
                        <Col className="d-flex justify-content-center align-items-center mt-5">
                            
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control type="email" value={email} onChange={(e) => {
                                        setEmail(e.target.value)
                                    }} placeholder="Enter email" />
                                    {error && (<Form.Text className=" text-danger ">
                                        {error}
                                    </Form.Text>)}
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Password</Form.Label>
                                    <div className="input-group">
                                        <Form.Control type={showPassWord ? "text" : "password"} value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
                                        <Button variant="outline-secondary" onClick={() => setShowPassword(!showPassWord)} > <BiShow size={19} /> </Button>
                                    </div>
                                    {error && (<Form.Text className=" text-danger ">
                                        {error}
                                    </Form.Text>)}
                                </Form.Group>

                                <Button disabled={!password || !email} className="border-0 butiko-button" variant="primary" type="submit">
                                    Submit
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                    <ToastContainer />
                </Container>
            </>)}
        </>
    )
}
export default Login
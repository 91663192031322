import React, { useState } from "react"
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { IoChevronBack } from "react-icons/io5";
import 'react-quill/dist/quill.snow.css';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import useGetDesigners from "./hooks/useGetDesigners";
import { Check } from 'lucide-react';
import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { message } from "antd"
import NewDesignerModal from "./modals/NewDesignerModal";

const Designers = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [show, setShow] = useState(false);
    const {
        designers,
        isLoading,
        isError,
        error,
        refetch
    } = useGetDesigners()
    console.log(designers);
    // const [approvedDesigners, setApprovedDesigners] = useState([]);
    const approveDesigner = (id) => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/admin-dashboard/products/approve-designer`
        const finalData = {
            designer_id: id
        }
        return axios.post(finalUrl, finalData, { withCredentials: true })
    }
    const approveDesignerMutation = useMutation({
        mutationFn: approveDesigner,
        onSuccess: () => {
            refetch()
            messageApi.open({
                type: 'success',
                content: 'Approved',
            });
        },
        onError: (error) => {
            console.log(error);

            messageApi.open({
                type: 'error',
                content: 'Something went wrong',
            });
        }
    })
    const handleApprove = (designerId) => {
        console.log(designerId);
        approveDesignerMutation.mutate(designerId);
    };

    return (
        <>
            <Container className="d-flex justify-content-center ">
                <Row className="flex justify-start ">
                    <Col className="flex justify-start ">
                        <Link
                            style={{ position: "absolute", top: "5px ", left: "0", padding: "16px" }}
                            to={"/nextpageoflogin"}
                        >
                            <IoChevronBack color="#000" size={50} />
                        </Link>
                    </Col>
                </Row>
                {/* <div className="container mx-auto p-6 overflow-x-auto rounded-t-lg  custom-scrollbar"> */}
                {/* <div className="p-4"> */}
                {/* <div className="flex justify-between items-center mb-4">
                            <h2 className="text-2xl font-bold">Designers</h2>
                        </div> */}
                <div className="container mx-auto p-4 mt-12">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-2xl font-bold">Designers List</h2>

                        <button onClick={()=>setShow(true)} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                            New Designer
                        </button>
                        <NewDesignerModal refetch={refetch} show={show} setShow={setShow} />
                    </div>
                    <ul className="space-y-2 p-0">
                        {Array.isArray(designers) && designers.map((designer) => (
                            <li key={designer.designer_id} className="flex items-center justify-between bg-white p-4 rounded-lg shadow">
                                <span className="text-lg">{designer.designer_name}</span>
                                <button
                                    onClick={() => handleApprove(designer.designer_id)}
                                    className={`px-4 py-2 rounded-md bg-green-500 text-white hover:bg-green-600`}
                                >
                                    
                                        Approve
                                    
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
                {/* </div> */}
                {/* </div> */}


                {contextHolder}

                <ToastContainer />
            </Container>
        </>
    )
}
export default Designers
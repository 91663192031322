import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const useGetCampaigns = () => {
    // const [page, setPage] = useState(initialPage);

    const getCampaigns = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/admin-dashboard/attributes/get-campaigns`;
        return axios.get(finalUrl, { withCredentials: true });
    };

    const { data, isLoading, isError, error, refetch } = useQuery({ queryKey: ["get-campaigns"], queryFn: getCampaigns });

    const campaigns = data?.data;

    return {
        campaigns,
        isLoading,
        isError,
        error,
        refetch

    };
};

export default useGetCampaigns;
import React from "react"
import Table from 'react-bootstrap/Table';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { IoChevronBack } from "react-icons/io5";
import 'react-quill/dist/quill.snow.css';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Spinner from 'react-bootstrap/Spinner';
import useGetApprovedSellers from "./hooks/useGetApprovedSellers";
import "./style.css"
import formatDate from "../../components/formatDate";
const Sellers = () => {

    const {
        sellers,
        isLoading: sellersIsLoading,
        isError: sellersIsError,
        error: sellersError,
        refetch: sellersRefetch
    } = useGetApprovedSellers()

    return (
        <>
            <Container style={{ position: "relative", flexDirection: "row", flexWrap: "wrap" }} className="d-flex justify-content-center vh-100 vw-100 ">
            <Row className="flex justify-start ">
                <Col className="flex justify-start ">
                    <Link
                        style={{ position: "absolute", top: "5px ", left: "0", padding: "16px" }}
                        to={"/nextpageoflogin"}
                    >
                        <IoChevronBack color="#000" size={50} />
                    </Link>
                </Col>
            </Row>

                
                <Row className="d-flex justify-content-center align-items-center   ">
                    <Col style={{ width: "90vw" }} className="d-flex justify-content-center align-items-center ">
                        <Table className="mobile-margin" striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>Seller ID</th>
                                    <th>Shop Name</th>
                                    <th>Email</th>
                                    <th>Phone Number</th>
                                    <th>Company Type</th>
                                    <th>Company Title</th>
                                    <th>Seller TC No</th>
                                    <th>Company Tax No</th>
                                    <th>Company Registration No</th>
                                    <th>Company Mersis No</th>
                                    <th>Company Tax Office</th>
                                    <th>Uploaded Products</th>
                                    <th>Registration Date</th>
                                    <th>Commission</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sellersIsLoading ? (
                                    <tr>
                                        <td colSpan="11">loading
                                            {/* <Spinner animation="border" variant="secondary" /> */}
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                variant="secondary"
                                            />
                                        </td>

                                    </tr>
                                ) : Array.isArray(sellers) && sellers.length === 0 ?
                                    (
                                        <tr>
                                            <td colSpan="11">not found</td>
                                        </tr>
                                    )
                                    : sellersIsError ? (
                                        <tr>
                                            <td colSpan="11">error</td>
                                        </tr>
                                    ) : (Array.isArray(sellers) && sellers.length > 0 && sellers.map((item) => {
                                        return (
                                            <tr key={item.seller_id}>
                                                <td> {item.seller_id} </td>
                                                <td> {item.shop_name} </td>
                                                <td> {item.email} </td>
                                                <td> {item.phone_number} </td>
                                                <td> {item.company_type} </td>
                                                <td> {item.company_title} </td>
                                                <td> {item.seller_TC_no} </td>
                                                <td> {item.company_tax_no} </td>
                                                <td> {item.company_registration_no} </td>
                                                <td> {item.company_mersis_no} </td>
                                                <td> {item.company_tax_office} </td>
                                                <td> {item.uploaded_products} </td>
                                                <td> {formatDate(item.registered_at)} </td>
                                                <td> {item.seller_commission} </td>
                                            </tr>
                                        );
                                    }))}


                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <ToastContainer />
            </Container>
        </>
    )
}
export default Sellers
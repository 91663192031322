import { useState, useEffect } from 'react';
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';

const useReportSummary = (initialSortBy = 'total_reports', initialPage = 0) => {
    const [sortBy, setSortBy] = useState(initialSortBy);
    const [page, setPage] = useState(initialPage);
    const [reportSummaryData, setReportSummaryData] = useState([]);

    const getReportSummary = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/admin-dashboard/reports/get-reports-summary`
        const finalData = {
            sort_by: sortBy,
            page: page
        };
        return axios.post(finalUrl, finalData, { withCredentials: true });
    };

    const getReportSummaryMutation = useMutation({
        mutationFn: getReportSummary,
        onSuccess: (data) => {
            setReportSummaryData(data.data);
        },
        onError: (error) => {
            console.log(error);
        }
    });

    useEffect(() => {
        getReportSummaryMutation.mutate();
    }, [page, sortBy]);
    // console.log( getReportSummaryMutation.isPending);
    return {
        sortBy,
        setSortBy,
        page,
        setPage,
        reportSummaryData,
        isLoading: getReportSummaryMutation.isPending,
        isError: getReportSummaryMutation.isError,
        error: getReportSummaryMutation.error,
        refetch: getReportSummaryMutation.mutate
    };
};

export default useReportSummary;
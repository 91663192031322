import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const useGetDesigners = () => {
    // const [page, setPage] = useState(initialPage);

    const getDesigners = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/admin-dashboard/products/get-designers`;
        return axios.get(finalUrl, { withCredentials: true });
    };

    const { data, isLoading, isError, error, refetch } = useQuery({ queryKey: ["get-designers"], queryFn: getDesigners });

    const designers = data?.data;

    return {
        designers,
        isLoading,
        isError,
        error,
        refetch

    };
};

export default useGetDesigners;
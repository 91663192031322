import React, { useEffect } from "react"
import { useState, useRef } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { IoChevronBack } from "react-icons/io5";
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import useGetCommissionSeller from "./hooks/useGetCommissionSeller";
import { EditOutlined } from '@ant-design/icons';
import UpdateCommissionModal from "./modals/UpdateCommissionModal";

const Commission = () => {
    const inputRef = useRef(null); // Create a reference for the input
    const [show, setShow] = useState(false);
    const [sellerId, setSellerId] = useState("");
    const [shopName, setShopName] = useState("");
    const [page, setPage] = useState(0);
    const [sellerToUpdate, setSellerToUpdate] = useState(0);
    const {
        sellers,
        isLoading,
        isError,
        error,
        refetch,
        isNext,
    } = useGetCommissionSeller(page, shopName, sellerId)
    useEffect(() => {
        inputRef.current.focus(); // Focus the input when called
    }, [])
    return (
        <>
            <Row className="flex justify-start ">
                <Col className="flex justify-start ">
                    <Link
                        style={{ position: "absolute", top: "5px ", left: "0", padding: "16px" }}
                        to={"/nextpageoflogin"}
                    >
                        <IoChevronBack color="#000" size={50} />
                    </Link>
                </Col>
            </Row>
            
            <div className="container mx-auto p-4 mt-16 bg-white rounded-lg ">
                <div className="mb-4 flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-2">
                    <input
                        type="number"
                        value={sellerId}
                        onChange={(e) => setSellerId(e.target.value)}
                        placeholder="Seller Id"
                        className="border p-2 rounded w-full bg-gray-200"
                    />
                    <input
                        ref={inputRef}
                        type="text"
                        value={shopName}
                        onChange={(e) => setShopName(e.target.value)}
                        placeholder="shop Name"
                        className="border p-2 rounded w-full bg-gray-200"
                    />
                    
                </div>

                <div className="overflow-x-auto rounded-t-lg  custom-scrollbar">

                    <table className="w-full border-collapse border ">
                        <thead>
                            <tr className="bg-gray-200">
                                <th className="border p-2">ID</th>
                                <th className="border p-2">shop Name</th>
                                <th className="border p-2">Company Title</th>
                                <th className="border p-2">Name</th>
                                <th className="border p-2">Commission</th>
                                <th className="border p-2">Picture</th>
                                <th className="border p-2">Edit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading ? (
                                <tr className="bg-gray-200 ">
                                    <th className="border p-2">
                                        <div className="bg-gray-300 h-6 w-16 rounded animate-pulse"></div>
                                    </th>
                                    <th className="border p-2">
                                        <div className="bg-gray-300 h-6 w-24 rounded animate-pulse"></div>
                                    </th>
                                    <th className="border p-2">
                                        <div className="bg-gray-300 h-6 w-28 rounded animate-pulse"></div>
                                    </th>
                                    <th className="border p-2">
                                        <div className="bg-gray-300 h-6 w-20 rounded animate-pulse"></div>
                                    </th>
                                    <th className="border p-2">
                                        <div className="bg-gray-300 h-6 w-32 rounded animate-pulse"></div>
                                    </th>
                                    <th className="border p-2 text-center">
                                        <div className="flex justify-center">
                                            <div className="bg-gray-300 h-10 w-10 rounded-full"></div>  
                                        </div>
                                    </th>
                                    <th className="border p-2">
                                        <div className="bg-gray-300 h-6 w-16 rounded animate-pulse"></div>
                                    </th>
                                </tr>

                            ) : isError ? (
                                <td colSpan="6" className="border p-2 text-center text-red-500">
                                    An error occurred while loading data
                                </td>
                            ) : sellers && Array.isArray(sellers) && sellers?.length === 0 ? (
                                <tr className="bg-gray-200">

                                    <td colSpan="7" className="border p-2 text-center text-gray-500">
                                        No Data
                                    </td>
                                </tr>
                            ) : (
                                sellers?.map((seller) => {
                                    return (
                                        <tr className="bg-gray-200 hover:bg-gray-300">
                                            <th className="border p-2">{seller?.seller_id}</th>
                                            <th className="border p-2">{seller?.shop_name}</th>
                                            <th className="border p-2">{seller?.company_title}</th>
                                            <th className="border p-2">{seller?.name}</th>
                                            <th className="border p-2">{seller?.seller_commission}</th>
                                            <th className="border p-2">
                                                <div className="flex justify-center">
                                                    {seller?.profile_picture ? (<img
                                                        src={seller?.profile_picture}
                                                        alt="Profile"
                                                        className="h-10 w-10 rounded-full object-cover"
                                                    />) : (
                                                        <div className="flex justify-center">
                                                            <div className="bg-red-300 h-10 w-10 rounded-full"></div>
                                                        </div>
                                                    )}
                                                </div>
                                            </th>
                                            <th className="border p-2">
                                                <button onClick={() => {
                                                    setSellerToUpdate(seller?.seller_id)
                                                    setShow(true)
                                                }} className="ml-2 flex items-center text-blue-500 hover:text-blue-700">
                                                    <EditOutlined />
                                                </button>
                                            </th>
                                        </tr>
                                    )
                                })
                            )
                            }
                        </tbody>
                    </table>
                </div>

                <div className="mt-4 flex justify-between">
                    <button
                        onClick={() => { if (page !== 0) setPage(prev => prev - 1) }}
                        disabled={page === 0}
                        className="bg-gray-300 px-4 py-2 rounded hover:bg-gray-400 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                        Previous
                    </button>
                    <button
                        onClick={() => setPage(prev => prev + 1)}
                        disabled={!isNext}
                        className="bg-gray-300 px-4 py-2 rounded hover:bg-gray-400 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                        Next
                    </button>
                </div>
            </div>
            <UpdateCommissionModal refetch={refetch} sellerToUpdate={sellerToUpdate} show={show} setShow={setShow} />
            <ToastContainer />
        </>
    )
}
export default Commission
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useMemo, useState } from 'react';

const useGetDebts = (page) => {
    // const [page, setPage] = useState(initialPage);
    const [isNext, setIsNext] = useState(false);

    const getDebts = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/admin-dashboard/transactions/get-completed-debts?page=${page}`;
        return axios.get(finalUrl, { withCredentials: true });
    };

    const { data, isLoading, isError, error, refetch } = useQuery({ queryKey: ["get-Debts",page], queryFn: getDebts });

    const debts = data?.data;
    useMemo(()=>{

        if(Array.isArray(debts) && debts.length ===11){
            setIsNext(true)
        }else{
            setIsNext(false)
        }
    },[data])
    // const debtIds = debts.map(debt => debt.order_transaction_id)
    const debtIds = Array.isArray(debts) ? debts.map(debt => debt.order_transaction_id || null).filter(id => id !== null) : [];

    return {
        debts,
        isLoading,
        isError,
        error,
        refetch,
        isNext,
        debtIds
    };
};

export default useGetDebts;
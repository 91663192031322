import React from "react"
import { useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { IoChevronBack } from "react-icons/io5";
import 'react-quill/dist/quill.snow.css';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import useGetCategories from "./hooks/useGetCategories";
import { Accordion, Card } from 'react-bootstrap';
import { FaChevronRight } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";

const CategoryItem = ({ category, depth = 0 }) => {
    const [isOpen, setIsOpen] = useState(false);
    const hasChildren = category.children_categories && category.children_categories.length > 0;

    return (
        <div className={`ml-${depth * 4}`}>
            <div
                className="flex items-center cursor-pointer hover:bg-gray-100 p-2 rounded"
                onClick={() => setIsOpen(!isOpen)}
            >
                {hasChildren && (
                    <span className="mr-2">
                        {isOpen ? <FaChevronDown size={16} /> : <FaChevronRight size={16} />}
                    </span>
                )}
                <span className="font-medium">{category.category_name}</span>
            </div>
            {isOpen && hasChildren && (
                <div className="ml-4">
                    {category.children_categories.map((child) => (
                        <CategoryItem key={child.category_id} category={child} depth={depth + 1} />
                    ))}
                </div>
            )}
        </div>
    );
};
const Categories = () => {
    const {
        categories,
        isLoading,
        isError,
        error,
        refetch
    } = useGetCategories()

    return (
        <>
            <Container className="d-flex justify-content-center ">
                <Row className="flex justify-start ">
                    <Col className="flex justify-start ">
                        <Link
                            style={{ position: "absolute", top: "5px ", left: "0", padding: "16px" }}
                            to={"/nextpageoflogin"}
                        >
                            <IoChevronBack color="#000" size={50} />
                        </Link>
                    </Col>
                </Row>
                <div className="container mx-auto p-6 overflow-x-auto rounded-t-lg  custom-scrollbar">
                    <div className="p-4">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-2xl font-bold">Categories</h2>

                            <Link className="link-style" to="/categories-create">
                                <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                                    Create New Category
                                </button>
                            </Link>
                        </div>
                        <Accordion>
                            {Array.isArray(categories) && categories.map((category) => (
                                <Card key={category.category_id} className="mb-2">
                                    <Accordion.Item eventKey={category.category_id.toString()}>
                                        <Accordion.Header>{category.category_name}</Accordion.Header>
                                        <Accordion.Body>
                                            {category.children_categories.map((child) => (
                                                <CategoryItem key={child.category_id} category={child} />
                                            ))}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Card>
                            ))}
                        </Accordion>
                    </div>
                </div>




                <ToastContainer />
            </Container>
        </>
    )
}
export default Categories
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import Otp from '../otp';
const TransactionModal = ({ show, onHide, refNo, transferName, transferIban }) => {
    const [hasMailSent, setHasMailSent] = useState(false);
    const hideModal = () => {
        setHasMailSent(false)
        onHide()
    }

    const [formData, setFormData] = useState({
        new_transfer_name: transferName,
        new_transfer_iban: transferIban
    });
    useMemo(() => {
        setFormData({
            new_transfer_name: transferName,
            new_transfer_iban: transferIban
        })
    }, [transferName, transferIban])
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const transactionRetry = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/admin-dashboard/transactions/retry`
        const finalData = {
            new_transfer_name: formData.new_transfer_name,
            new_transfer_iban: formData.new_transfer_iban,
            ref_no: refNo
        }
        return axios.post(finalUrl, finalData, { withCredentials: true })
    }
    const retryMutation = useMutation({
        mutationFn: transactionRetry,
        onSuccess: () => {
            toast.success("Email sent succsessfuly",)
            setHasMailSent(true)
        },
        onError: (error) => {
            toast.error("something went wrong")
            console.log(error);
        }
    })
    const handleSubmit = (e) => {
        e.preventDefault();
        retryMutation.mutate();
    };
    return (
        <>
            <Modal size="lg" show={show} onHide={hideModal}>
                <Modal.Header closeButton>
                    <Modal.Title>New Transaction</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {hasMailSent ?
                        (<>
                            <Otp refNo={refNo} onHide={onHide} setHasMailSent={setHasMailSent}/>
                        </>) :
                        (
                            <div className=" bg-white rounded-lg ">
                                <Form onSubmit={handleSubmit} className="space-y-4">
                                    <Form.Group controlId="new_transfer_name">
                                        <Form.Label className="block text-gray-700 text-sm font-bold mb-2">
                                            new_transfer_name
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="new_transfer_name"
                                            required
                                            value={formData.new_transfer_name}
                                            onChange={handleChange}
                                            placeholder="Enter first value"
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="new_transfer_iban">
                                        <Form.Label className="block text-gray-700 text-sm font-bold mb-2">
                                            new_transfer_iban
                                        </Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            name="new_transfer_iban"
                                            value={formData.new_transfer_iban}
                                            onChange={handleChange}
                                            placeholder="Enter second value"
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        />
                                    </Form.Group>

                                    <Button
                                        variant="primary"
                                        type="submit"
                                        className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                    >
                                        Submit
                                    </Button>
                                </Form>
                            </div>
                        )}
                </Modal.Body>

                {/* <ToastContainer /> */}
            </Modal>
        </>
    )
}
export default TransactionModal
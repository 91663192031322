import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const useGetApprovedSellers = () => {
    // const [page, setPage] = useState(initialPage);

    const getApprovedSellers = () => {
        const finalUrl = `${process.env.REACT_APP_API_URL}/api/v1/admin-dashboard/file/get-approved-sellers`;
        return axios.get(finalUrl, { withCredentials: true });
    };

    const { data, isLoading, isError, error, refetch } = useQuery({ queryKey: ["get-approved-sellers"], queryFn: getApprovedSellers });

    const sellers = data?.data;

    return {
        sellers,
        isLoading,
        isError,
        error,
        refetch
    };
};

export default useGetApprovedSellers;